<template>

  

    <b-modal
            ref="modalRoomies"
            centered
            hide-footer
            size="sm"
            title="Enviar dinero a My Roomie"

            @hidden="closeModalRoomie"
          >
            <div class="d-block text-center">

                <b-row >

                    <b-col
                            sm="12"
                            md="12"
                            lg="12"
                            xl="12"
                          >

                               <b-alert
                          variant="secondary"
                          show
                          style="margin-top:0px;margin-bottom: 10px;"
                        >
                          <div class="alert-body">
                            <b-skeleton v-if="loading" style="margin-bottom:0px" width="100%" height="40px"  ></b-skeleton>

                            <p v-else class="saldoDisponible"><span style="font-size:25px">$</span>{{saldoDisponible}}<small class="currency2">USD</small></p>
                            <p style="font-size: 12px;    margin: 0;">Saldo disponible para enviar</p>

                          
                          </div>
                        </b-alert>

                        

                          </b-col>

                          <b-col
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                          style="margin-top: 5px;margin-bottom: 5px;"
                        >

                          <b-form
                            autocomplete="off"
                            @submit.prevent="onSubmit"
                          >

                          <b-row>

                                 <b-col
                                    cols="12"
                                      sm="12"
                                      md="12"
                                      lg="12"
                                      xl="12"
                                    >

                                    

                                     <p
                                        class="textLabel"
                                        style="text-align: center;margin-top: 0px;margin-bottom: 5px;"
                                      >
                                        ¿Cuánto vas a transferir? (*):</p>

                                        <AutoNumericVue
                                        v-model="mount"
                                        required
                                        :readonly="isDisabled"
                                        type="text" inputmode="decimal"
                                        @blur="onBlurMount"
                                        class="autonumeric_input"
                                        :options="json_opciones"
                                        :placeholder="'$0.00'"
                                    ></AutoNumericVue>

                                    </b-col>


                                    <b-col
                                                sm="12"
                                                md="12"
                                                lg="12"
                                                xl="12"
                                              >

                                   <p class="textLabel" style="text-align: left;margin-top: 0px;margin-bottom: 5px;">
                                      Ingrese el nombre de usuario de My Roomies (*):</p>
              
                                      <b-form-input
                                              v-model="alias"
                                              placeholder="Ingrese el nombre de usuario de My Roomies"
                                              required
                                              :readonly="hasAlias"
                                              type="text"
                                            />


                                              </b-col>

                                

                    

                                       <b-col
                                                sm="12"
                                                md="12"
                                                lg="12"
                                                xl="12"
                                              >
                                                <p
                                                  class="textLabel"
                                                  style="text-align: center;margin-top: 10px;margin-bottom: 10px;"
                                                >
                                                  Ingrese su código PIN (*): <feather-icon
                                                                    icon="HelpCircleIcon"
                                                                    size="16"
                                                                      v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                                                    title="Código PIN de seguridad"
                                                                    style="margin-right: 5px;cursor:pointer;"
                                                                    /> </p>

                                                <PincodeInput
                                                  v-model="pin"
                                                  placeholder="-"
                                                  :length="6"
                                                  :autofocus="false"
                                                  :disabled="isDisabled"
                                                  :secure="true"
                                                  required
                                                />

                                              </b-col>




                                              <b-col
                                              cols="12"
                                                sm="12"
                                                md="12"
                                                lg="12"
                                                xl="12"
                                              >

                                                <b-button
                                                  id="btn_transferir"
                                                  ref="btn_transferir"
                                                  type="submit"
                                                  variant="primary"
                                                  block
                                                  :disabled="isDisabled"
                                                >

                                                  Transferir dinero

                                                </b-button>

                                              </b-col>

                                         


                          </b-row>
                          
                          
                          </b-form>

                          <b-alert
                          variant="primary"
                          show
                          style="margin-top:15px"
                        >
                          <div class="alert-body">
                            <b-skeleton v-if="loading" style="margin-bottom:10px" width="100%" height="10px"  ></b-skeleton>
                             <p v-else style="text-align: left;font-size:13px; margin-bottom: 0px;">(*) El monto mínimo de envio es de <b>${{minEnvio}} USD.</b></p>
                             <b-skeleton v-if="loading" style="margin-bottom:10px" width="100%" height="10px"  ></b-skeleton>
                             <p v-else style="text-align: left;font-size:13px; margin-bottom: 0px;">(*) El monto máximo de envio es de <b>${{maxEnvio}} USD.</b></p>
                            
                             <b-skeleton v-if="loading" style="margin-bottom:10px" width="100%" height="10px"  ></b-skeleton>
                                <p v-else style="text-align: left;font-size:13px; margin-bottom: 0px;">(*) Se cobrará una comisión de <b>${{comision}} USD</b> </p>
                            
                          </div>
                        </b-alert>

                          
                    

                        </b-col>

                    


                </b-row>

      
             
      
            </div>
      
          </b-modal>
          
          </template>
          
          <script>
          import {
            BButton, BRow,VBPopover, BCol, BCard, BForm, BSkeleton, BAlert, BFormInput
          } from 'bootstrap-vue'
          
          import PincodeInput from 'vue-pincode-input'
          import AutoNumericVue from 'autonumeric-vue/src/components/AutoNumericVue';
          export default {
            components: {
                PincodeInput,
                AutoNumericVue,
                BFormInput,
                BSkeleton,
                BForm,
                BAlert,
              BButton,
              BRow,
              BCol,
              BCard,
            },
            directives: {
              'b-popover': VBPopover,
            },
            props: [ 'tokenAuth', 'userId','roomie'],
            data() {
          
          
              return {
                isActive:false,
                pin:"",
                loading:true,
                isDisabled:true,
                mount:"",
                saldoDisponible:0,minEnvio:5,maxEnvio:100,comision:0.50,
                json_opciones:{},
                alias:this.roomie,
                hasAlias:false,
              
              }
            },
            computed: {
          
            },
            watch: {
          
            },
            mounted() {

              if(this.alias != ""){

                this.hasAlias=true;

              }else{
                this.hasAlias=false;
              }
       
            
          this.json_opciones={allowDecimalPadding: "false", currencySymbol: "$", decimalCharacterAlternative: ",",decimalCharacter:".",   minimumValue: 0, maximumValue:this.maxEnvio, modifyValueOnWheel: false };

            this.$refs.modalRoomies.show();
            this.getSaldo();

            },
            methods: {


                getSaldo(){

                    this.loading = true;
                    this.isDisabled=true;
     
                    this.$https.post('/profile/getBalance/', { tokenAuth: this.tokenAuth, userId: this.userId }).then(response => {



                    if (response.data.code == 200) {
                    this.loading = false;
                    this.isDisabled=false;

                    this.saldoDisponible= response.data.balance;

                    } else {




                    if (response.data.code == 401) {

                    this.$toast.error(response.data.message, {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                    })
                    
                    localStorage.removeItem('userData')


                    // Redirect to login page
                    this.$router.push({ name: 'auth-login' })
                    } else {
                    this.getSaldo();
                    }
                    }
                    }).catch(error => {
                    this.getSaldo();
                    })
                    },

                    onBlurMount(){



                        if(this.mount == null || this.mount == "null" || this.mount ==""){
                        this.mount ="";



                        }else{

                            if(Number(this.mount) < Number(this.minEnvio)){

                            this.mount ="";
                                    this.$toast.error("La cantidad a enviar debe ser mayor a la cantidad mínima de envio de $" +this.minEnvio+" USD" , {
                                        position: 'top-right',
                                        timeout: 3010,
                                        closeOnClick: true,
                                        pauseOnFocusLoss: false,
                                        pauseOnHover: true,
                                        draggable: true,
                                        draggablePercent: 0.4,
                                        showCloseButtonOnHover: false,
                                        hideProgressBar: true,
                                        closeButton: 'button',
                                        icon: true,
                                        rtl: false,
                                        })



                            }else{

                            if(Number(this.mount) > Number(this.maxEnvio)){
                                this.mount="";

                                this.$toast.error("La cantidad a enviar debe ser menor a $" +this.maxEnvio+" USD" , {
                                    position: 'top-right',
                                    timeout: 3010,
                                    closeOnClick: true,
                                    pauseOnFocusLoss: false,
                                    pauseOnHover: true,
                                    draggable: true,
                                    draggablePercent: 0.4,
                                    showCloseButtonOnHover: false,
                                    hideProgressBar: true,
                                    closeButton: 'button',
                                    icon: true,
                                    rtl: false,
                                })
                            }else{

                                
                            if(Number(this.mount) > Number(this.saldoDisponible)){
                                

                                this.mount="";

                                this.$toast.error("La cantidad a enviar debe ser menor a su saldo disponible de $" +this.saldoDisponible+" USD" , {
                                    position: 'top-right',
                                    timeout: 3010,
                                    closeOnClick: true,
                                    pauseOnFocusLoss: false,
                                    pauseOnHover: true,
                                    draggable: true,
                                    draggablePercent: 0.4,
                                    showCloseButtonOnHover: false,
                                    hideProgressBar: true,
                                    closeButton: 'button',
                                    icon: true,
                                    rtl: false,
                                    })




                            }

                            }




                            }

                        

                            


                        }

                        },
    
                  
             closeModalRoomie(){
                    this.$eventBus.$emit('reiniciarModalRoomie')

                    
                },

           
                onSubmit(event) {
                    event.preventDefault()

            

                      this.mount= Number(this.mount).toFixed(2);


                    const userId_json = {
                      userId: this.userId, pin: this.pin, mount: this.mount,  alias:this.alias
                    }



                    const user_string = JSON.stringify(userId_json)


                    const dataEncripted = this.$encryptBackoffice.encrypt(user_string)
                  
                    this.isDisabled = true

                    document.getElementById('btn_transferir').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Transferiendo'

                    this.$https.post('/interbilletera/payMyRoomies/', { tokenAuth: this.tokenAuth, data: dataEncripted}).then(response => {
                    this.isDisabled = false

                  
                
                      document.getElementById('btn_transferir').innerHTML = 'Transferir';
                    

                      if (response.data.code == 200) {

               

                        this.$toast.success(response.data.message, {
                          position: 'top-right',
                          timeout: 3010,
                          closeOnClick: true,
                          pauseOnFocusLoss: false,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 0.4,
                          showCloseButtonOnHover: false,
                          hideProgressBar: true,
                          closeButton: 'button',
                          icon: true,
                          rtl: false,
                        });
                        this.$eventBus.$emit('reiniciarSaldos')
                        this.$eventBus.$emit('reiniciarTransaccionesHome');

                        this.closeModalRoomie();


                      } else {
                        this.isDisabled = false

                        this.$toast.error(response.data.message, {
                          position: 'top-right',
                          timeout: 3010,
                          closeOnClick: true,
                          pauseOnFocusLoss: false,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 0.4,
                          showCloseButtonOnHover: false,
                          hideProgressBar: true,
                          closeButton: 'button',
                          icon: true,
                          rtl: false,
                        })

                        if (response.data.code == 401) {
                          
                          localStorage.removeItem('userData')


                          // Redirect to login page
                          this.$router.push({ name: 'auth-login' })
                        } else if (response.data.code == 503) {
                          this.pin = ''
                        } else if(response.data.code == 501){
                                this.$swal({
                                    title: 'Tu perfil debe estar verificado para realizar esta acción',
                                    text: `¿Deseas verificar tu cuenta ahora?`,
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonText: 'Verificar cuenta',
                                    cancelButtonText: 'No',
                                    customClass: {
                                      confirmButton: 'btn btn-primary',
                                      cancelButton: 'btn btn-outline-secondary ml-1',
                                    },
                                    buttonsStyling: false,
                                  }).then(result => {
                                    if (result.value) {

                                      this.$router.push({ name: 'perfil', hash: '#verificacion' })

                                    }
                                  })
                              }
                      }
                    }).catch(error => {
                      this.$toast.error("Ocurrió un error inesperado", {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                      })
                    })

                    

                  
                  },
             
          
            },
          }
          </script>
          
          <style lang="scss">
          
          
          
          </style>
          